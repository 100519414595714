@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

:root {
  --swiper-theme-color: #fff !important;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
a {
  text-decoration: none;
  color:  unset;
}

@media screen and (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
li {
  list-style-type: none !important;
}
a {
  text-decoration: none;
}
input {
  border: none;
  outline: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.scroll-to-top {
  position: fixed;
  z-index: 99;
  right : 0;
  bottom : 0;
  margin: 0 2rem 2rem 0;
  img{
    width: 3rem;
  }
  transition: .3s ease;
}
.scroll-to-top:hover{
  cursor: pointer;
}
.scroll-to-top.hide{
  opacity: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
