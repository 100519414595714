.contact.dark {
  background-color: #1d2b3a;
  * {
    color: #fff;
  }
  .mini-footer {
    background-color: #fff;
    p {
      a {
        color: #ff4500;
      }
    }
  }
}
.contact {
  padding: 5rem 0 0 0;
  .title {
    h1 {
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
    }
  }
  .desc {
    margin: 2rem 0;
    p {
      text-align: center;
    }
  }
  .body {
    padding: 3rem 5rem;
    display: flex;
    justify-content: space-between;
    .col {
      width: calc(100% / 3.5);
      .element {
        display: flex;
        margin-bottom: 1.5rem;
        .icon {
          margin-right: 1rem;
          img {
            width: 2.5rem;
          }
        }
        .details {
          .title {
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
            font-weight: 600;
            img{
              display: none;
            }
          }
        }
      }
    }
    .address {
      display: flex;
      //   width: ;
      .icon {
        margin-right: 1rem;
        img {
          width: 2.5rem;
        }
      }
      .content {
        .title {
          font-size: 1.25rem;
          margin-bottom: 0.5rem;
          font-weight: 600;
          img{
            display: none;
          }
        }
        .address-detail {
          margin: 1rem 0;
          .title {
            p {
              font-size: 1rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .form {
      background-color: #1d2b3a;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .container {
        padding: 2rem 0;
        .error {
          p {
            color: #f00;
            text-align: center;
            font-size: 0.75rem;
          }
        }
        .input-field-container {
          text-align: center;
          input,
          textarea {
            background-color: transparent;
            outline: none;
            border: 1px solid #ffffff;
            width: 100%;
            padding: 0.75rem 0.75rem;
            margin-bottom: 0.5rem;
            border-radius: 1rem;
            color: #fff;
          }
          button {
            outline: none;
            border: none;
            background-color: #ff4500;
            border-radius: 2rem;
            padding: 0.75rem 1.5rem;
            // color: #fff;
            span {
              display: inline-block;
              transition: 0.2s ease;
            }
          }
          button:hover {
            cursor: pointer;
            span {
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }
  .map {
    height: 50vh;
    iframe {
      width: 100%;
      border: none;
      outline: none;
      display: block;
      height: 100%;
    }
  }
  .mini-footer {
    width: 100vw;
    background-color: #1d2b3a;
    padding: 1rem 0;
    p {
      text-align: center;
      color: #ff4500;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact {
    .title {
      h1 {
        font-size: 2rem;
      }
    }
    .desc {
      width: 80%;
      margin: 2rem auto;
    }
    .body {
      padding: 1rem 2rem;
      .col {
        width: 100%;
        .element {
          justify-content: center;
          margin: 3rem 0;
          .icon {
            display: none;
          }
          .details{
            .title{
              display: flex;
              align-items: center;
              text-align: center;
              img{
                display: inline-block;
                width: 2rem;
                margin-right: .5rem;
              }
            }
            
          }
        }
      }
      .address {
        .icon {
          display: none;
        }
        .content{
          .title{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              display: inline-block;
              width: 2rem;
              margin-right: .5rem;
            }
          }
          .address-detail{
            .title{
              p{
                text-align: center;
              }
            }
            .data{
              text-align: center;
            }
          }
        }
      }
      flex-direction: column;
      .form {
        display: none;
      }
    }
    .map {
      height: 50vh;
    }
  }
}
