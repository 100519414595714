.Universal-Home {
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none;
  list-style: none;
  background-color: whitesmoke;
  overflow-x: hidden;

  .home-header {
    height: 100vh;
    position: relative;
    width: 100%;
    .navbar-home {
      position: absolute;
      z-index: 99;
      width: 100%;
    }
    .tags {
      position: absolute;
      z-index: 9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      margin: 0 5rem;
      h1 {
        font-size: 4rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #fff;
      }
      p {
        color: #fff;
        font-size: 1.2rem;
        margin: 2rem 0;
      }
    }
    .slider {
      position: absolute;
      height: 100%;
      width: 100%;
      .myswiper {
        height: 100%;
        width: 100%;
        .swiper-slide {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(50%);
          }
        }
      }
    }
  }

  .About {
    margin: 4rem;
    .title {
      margin: 2rem 0;
      h1 {
        text-align: center;
        color: #ff4500;
        font-family: "Oswald", sans-serif;
        font-size: 3rem;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col {
        width: 45%;
      }
      .Us {
        p {
          margin: 2rem 0;
        }
        p.highlight{
          color: #ff4500;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
      .col {
        img {
          width: 90%;
        }
      }
    }
  }

  .slider-container {
    width: 100vw;
    overflow-x: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2vw 0 4vw 0;
    padding: 0vw 0vw 5vw 0vw;
    .Heading {
      font-size: 2vw;
      color: orangered;
      padding: 4vw 0vw 6vw 0vw;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
    }
    /* Custom styles for Swiper container */

    .swiper {
      width: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide.swiper-slide-active {
      box-shadow: 2px 2px 100px 8px rgba(0, 0, 0, 0.25);
    }

    .swiper-button-prev {
      left: 10px;
    }

    .swiper-button-next {
      right: 2vw;
    }

    .swiper-slide .slider-card {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      width: 55vw;
      background-color: #1f2730;
      border-radius: 0.6vw;
      .content {
        width: 35vw;
        padding: 2vw;
        color: white;

        h1 {
          font-size: 2vw;
          font-weight: 700;
          font-family: "Montserrat", sans-serif;
        }

        p {
          padding: 1.2vw 0vw;
          font-size: 0.9vw;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          line-height: 1.2vw;
        }

        button {
          border: unset;
          padding: 0.6vw 0.8vw;
          background-color: rgba(251, 109, 15, 0.955);
          border-radius: 1vw;
          color: white;

          font-size: 0.8vw;
          font-weight: 400;
        }
        button:hover {
          cursor: pointer;
        }
      }
      .img {
        img {
          width: 30vw;
          border-top-right-radius: 0.5vw;
          border-bottom-right-radius: 0.5vw;
          height: 26vw;
        }
      }
    }
    .swiper-slide img {
      display: block;
      width: 55vw;
      object-fit: cover;
    }
  }
  .Innovative {
    color: orangered;
    text-align: center;
    font-family: "Oswald", sans-serif;
  }

  .Services {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;

    .heading h1 {
      justify-content: flex-start;
      align-items: baseline;
      color: orangered;
      font-size: 3vw;
      font-family: "Oswald", sans-serif;
    }

    .Logo {
      justify-content: center;
      text-align: center;
      align-items: center;
      width: 100%;
    }

    .col {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      text-align: left;
      margin: 4vw 10vw;
    }

    .row {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      width: 30%;
      height: 15vh;
      background-color: #ffffff;
      border-radius: 10px;
      border: 2px solid #ffffff;
      box-shadow: 2px 2px 100px 2px rgba(0, 0, 0, 0.25);
      margin: 1vw 0;
      padding: 5vw 0vw;

      img {
        width: 4.2vw;
        margin: 0vw auto;
        padding: 0.8vw 0vw;
        transition: transform 0.2s ease;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    .Ser {
      font-size: 1.25vw;
      font-weight: 500;
      text-align: center;
      width: 100%;
      font-family: "Oswald", sans-serif;
      align-items: center;
    }
  }
  .clients {
    max-width: 90%;
    margin: 2rem auto;
    .title {
      h1 {
        text-align: center;
        font-family: "Oswald", sans-serif;
        font-size: 3vw;
        color: orangered;
      }
    }
    .client-logos {
      margin: 10% 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .client-logo {
        width: 10%;
        img {
          width: 100%;
        }
      }
    }
  }
  .get-in-touch {
    .body {
      margin: 2rem 3rem;
      background-color: #ff4500;
      padding: 2rem 3rem;
      border-radius: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .col:first-child {
        width: 55%;
      }
      .col:last-child {
        width: 35%;
      }
      .col {
        .title {
          h2 {
            margin: 0.5rem 0;
            font-size: 3rem;
            font-weight: 500;
            font-family: "Oswald", sans-serif;
            color: #fff;
            margin: 1rem 0;
          }
        }
        .desc {
          p {
            font-size: 1.25rem;
            color: #fff;
          }
        }
        .form {
          .error {
            p {
              text-align: center;
              color: #f00;
            }
          }
          background-color: #1f2730;
          padding: 1rem;
          border-radius: 1.2rem;
          width: 100%;
          form {
            width: 100%;
            display: block;
            position: relative;
            .input-field-container {
              width: 100%;
              margin: 1rem 0;
              input,
              textarea {
                width: 96%;
                padding: 1rem 2%;
                border: none;
                outline: none;
                border-radius: 0.75rem;
                background-color: transparent;
                border: 1px solid #c1c1c1;
                color: #fff;
              }
              text-align: center;
              button {
                outline: none;
                border: none;
                padding: 1rem 2rem;
                border-radius: 1.2rem;
                background-color: #ff4500;
                font-size: 1rem;
              }
              button:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .navbar {
      padding: 0;
      cursor: pointer;
    }

    .navbar .menu-hamburger {
      display: block;
    }

    .nav-links {
      top: 0;
      left: 0;
      position: absolute;
      width: 80%;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(8px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-links .mobile-menu {
      margin-left: 0;
    }

    .nav-links ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .navbar .nav-links ul li {
      margin: 25px 0;
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .Universal-Home {
    .home-header {
      height: 80vh;
      .tags {
        margin: 0 2rem;
        h1 {
          font-size: 2rem;
        }
      }
    }
    .About {
      margin: 2rem;
      .title {
        h1 {
          font-size: 2rem;
        }
      }
      .container {
        flex-direction: column-reverse;
        .col {
          width: 100%;
          img {
            width: 100%;
          }
          p {
            text-align: justify;
          }
          p.highlight{
            text-align: center;
            font-size: 1rem;
          }
        }
      }
    }
    .slider-container {
      .Heading {
        font-size: 1.25rem;
        padding: 2rem;
        font-size: 600;
      }
      .mySwiper {
        .slider-card {
          flex-direction: column-reverse;
          justify-content: space-between;
          border-radius: 1rem;
          height: fit-content;
          width: fit-content;
          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50vw;
            padding: 1rem;
            h1 {
              font-size: 1rem;
              text-align: start;
            }
            p {
              font-size: 0.7rem;
              line-height: normal;
              margin: 0.5rem 0;
              text-align: center;
            }
            button {
              font-size: 0.75rem;
              padding: 0.5rem 0.75rem;
            }
          }
          .img {
            width: 100%;
            height: 20vh;
            img {
              height: 100%;
              border-radius: 1rem 1rem 0 0;
              width: 100%;
            }
          }
        }
      }
    }
    .Services {
      .heading {
        h1 {
          font-size: 1.5rem;
        }
      }
      .Logo {
        .col {
          .row {
            width: 45%;
            height: 10vh;
            margin: 1rem 0;
            img {
              width: 2rem;
            }
            .Ser {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
    .clients {
      .title {
        h1 {
          font-size: 1.5rem;
        }
      }
      .client-logos {
        justify-content: space-around;
        .client-logo {
          width: 30%;
          margin: 1rem;
        }
      }
    }
    .get-in-touch {
      .body {
        margin: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        .col:first-child,
        .col:last-child {
          width: fit-content;
        }
        .col {
          .title {
            h2 {
              font-size: 2.25rem;
              text-align: center;
            }
          }
          .desc {
            margin: 2rem 0;
            p {
              font-size: 1rem;
              text-align: center;
            }
          }
          .form {
            width: fit-content;
            margin: auto;
            form {
              .input-field-container {
                input,
                textarea {
                  padding: 0.75rem 2%;
                }
                button {
                  padding: 0.75rem 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
