.Navbarheader.scrolled {
  background-color: #fff;
  z-index: 999;
  position: fixed;
  box-shadow: 1px 2px 120px 2px #71717128;
  nav {
    .nav-links {
      .active {
        a {
          color: orangered;
        }
      }
    }
    .white {
      li {
        a {
          color: #000;
        }
        a {
          button {
            color: #fff;
          }
        }
      }
    }
  }
}
.mobile {
  display: none;
}
.Navbarheader {
  width: 90vw;
  padding: 0 5vw;
  transition: all 0.5s;
  nav {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    transition: all 0.5s;
    z-index: 1000;
    .logo {
      .conditional-rendering-logo img {
        width: 16vw;
        // padding-left: 4vw;
      }
    }

    .nav-links {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      cursor: pointer;

      li {
        margin: 2vw;
        font-size: 1.2rem;
        // margin-top: auto;
        a {
          button {
            border: none;
            outline: none;
            padding: 0.75rem 1.5rem;
            border-radius: 1.2rem;
            background-color: #ff4500;
            color: #fff;
            font-size: 1rem;
            transition: 0.2s ease;
          }
        }
        button:hover {
          cursor: pointer;
          transform: scale(1.1);
          a {
            color: #fff;
          }
        }
      }
      .active {
        a {
          color: orangered;
        }
      }
      li:hover a {
        color: orangered;
        transition-duration: 0.2s;
        transform: scale(1.1);
      }
    }
    .white {
      li {
        a {
          color: white;
        }
      }
    }
    .black {
      li {
        a {
          color: black;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .navbar.mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 61px 18px rgba(199, 199, 199, 0.5);
    -moz-box-shadow: 0px 2px 61px 18px rgba(199, 199, 199, 0.5);
    box-shadow: 0px 2px 61px 18px rgba(199, 199, 199, 0.5);
    .ham-menu {
      z-index: 9999;
      .ham-icon {
        span {
          display: block;
          width: 1.75rem;
          height: 0.15rem;
          margin: 0.25rem;
          transition: 0.2s ease;
        }
      }
      .ham-icon.black {
        span {
          background-color: #000;
        }
      }
      .ham-icon.white {
        span {
          background-color: #000;
        }
      }
    }
    .ham-menu.active {
      .ham-icon {
        span {
          opacity: 1;
          transform: rotate(45deg);
          margin: 0;
          background-color: #000;
        }
        span:nth-last-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        span:nth-last-child(3) {
          transform: rotate(-45deg) translate(-3px, 2px);
        }
      }
    }
    .logo {
      display: flex;
      // background-color: red;
      justify-content: center;
      img {
        width: 8rem;
        margin-left: auto !important;
      }
    }
    .dummy {
      width: 1.5rem;
    }
    .contact.black {
      p {
        a {
          color: #000;
        }
      }
    }
    .contact.white {
      p {
        a {
          color: #e0e0d7;
        }
      }
    }
    .side-bar-menu {
      z-index: 9999;
      position: absolute;
      transform: translateX(-100%);
      transition: 0.3s ease;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      ul {
        margin: 20% 0;
        li {
          a {
            button {
              border: none;
              outline: none;
              padding: 0.75rem 1.5rem;
              border-radius: 1.2rem;
              background-color: #ff4500;
              color: #fff;
              font-size: 1rem;
              transition: 0.2s ease;
            }
          }
          button:hover {
            cursor: pointer;
            transform: scale(1.1);
            a {
              color: #fff;
            }
          }
          a {
            color: #000;
            display: block;
            width: 100%;
            text-align: center;
            padding: 1rem 0;
            font-size: 1.25rem;
            font-weight: 600;
          }
          p {
            a {
              font-size: 1rem;
            }
            text-align: right;
          }
        }
        li.active {
          a {
            color: #ff4500;
          }
        }
      }
      .social-icons {
        padding: 0 3rem;
        ul {
          display: flex;
          justify-content: space-between;
          li {
            a {
              img {
                width: 2rem;
              }
            }
          }
        }
      }
    }
    .side-bar-menu.active {
      transform: translateX(0);
    }
  }
}
